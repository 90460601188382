const columns = [
  {
    label: "日期",
    prop: "date",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(英文)",
    prop: "creator_handle",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(原文)",
    prop: "creator_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频ID",
    prop: "video_id",
    align: "center",
    width: 150,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "链接",
    prop: "link",
    align: "center",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频名称",
    prop: "title",
    align: "center",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "发布时间",
    prop: "publish_time",
    align: "center",
    width: 180,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频时长",
    prop: "duration",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "千次曝光成交金额($)",
    prop: "rpm_amount",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "播放量",
    prop: "watch_cnt",
    align: "center",
    width: 110,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交件数",
    prop: "item_sold_cnt",
    align: "center",
    width: 110,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "完播率(%)",
    prop: "finish_rate",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "商品ID",
    prop: "products",
    align: "center",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "点赞数",
    prop: "like_cnt",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "评论数",
    prop: "comment_cnt",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "分享数",
    prop: "share_cnt",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "看播-点击转化率(%)",
    prop: "ctr",
    align: "center",
    width: 140,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "点击-成交转化率(%)",
    prop: "co",
    align: "center",
    width: 140,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
];
export default columns;
